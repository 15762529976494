














import { Component } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MasterDepositoryModule from '@/store/modules/master/depository'
// components
import DepositoryExpandableItem from '@/components/views/depository/DepositoryExpandableItem.vue'
import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import { NameValueResource } from '@/store/types'

@Component({
  components: {
    DepositoryExpandableItem,
    LinkTabs,
  },
})
export default class MasterDepository extends NotifyMixin {

  private get tabs() {
    return [
      {
        name: 'Полезные материалы',
        to: { name: 'master.depository.item.materials' },
      },
      {
        name: 'Учебные файлы',
        to: { name: 'master.depository.item.files' },
      },
    ]
  }

  private get courseID () {
    return +this.$route.params.courseID
  }

  private get breadcrumbs () {
    return [{ name: 'База знаний', path: '/master/depository' }]
  }

  private get findCourse() {
    return MasterDepositoryModule.courses.data.find(course => course.id === this.courseID)
  }

  private course: NameValueResource | null = null

  private mounted() {
    MasterDepositoryModule.setCurrentCourseID(this.courseID)
    if (!this.findCourse || this.findCourse.id !== this.courseID) {
      MasterDepositoryModule.fetchCourse(+this.courseID)
        .then(response => {
          this.course = response
        })
        .catch(this.notifyError)
    } else {
      this.course = { name: this.findCourse.title, value: this.findCourse.id }
    }
  }
}
