



















import { Component, Prop } from 'vue-property-decorator'

// components
import AttachmentsWrapper from '@/components/_uikit/AttachmentsWrapper.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { MediaResource, NameValueResource } from '@/store/types'

@Component({
  components: {
    AttachmentsWrapper,
  },
})
export default class DepositoryExpandableItem extends NotifyMixin {
  @Prop({ default: false })
  private readonly!: boolean

  @Prop({ default: () => ([]) })
  private media!: MediaResource[]

  @Prop({ default: '' })
  private title!: string

  @Prop({ default: () => ([]) })
  private additionalUploadParams!: NameValueResource[]

  private handleSelectFiles (mediaId: number) {
    this.$emit('select-files', mediaId)
  }

  private handleDeleteFile (id: number) {
    this.$emit('delete-file', id)
  }

}
